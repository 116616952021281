import $ from 'jquery-slim';

class Select
{
    constructor($element)
    {
        this.$element = $element;

        this.$element.on('loaded.bs.select', this.loaded.bind(this));
    }

    loaded()
    {
        this.$parent = this.$element.parent();

        let observerOptions = {
            childList: false,
            attributes: true,
            subtree: false
        }

        this.observer = new MutationObserver(this.change.bind(this));
        this.observer.observe(this.$element[0], observerOptions);

        this.change();

    }

    change()
    {
        if (this.$element[0].hasAttribute('invalid')) this.$parent.attr('invalid', '');
        else this.$parent.attr('invalid', null);
    }
}

class OptSelect
{
    constructor()
    {
        $(document).ready(this.init.bind(this));
    }

    init()
    {
        let $selects = $('.selectpicker');

        if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent))) $selects.selectpicker({mobile:false});

        this.selects = [];

        for (let i = 0; i < $selects.length; i++) this.selects.push(new Select($selects.eq(i)));
    }
}



let optSelect = new OptSelect();